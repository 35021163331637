import { REQUEST_RESPONSE } from '../constants/types';

export const handleRequestHelper = async ({
  endpoint,
  onSuccess = () => {},
  onFailure = () => {},
  errorCodesNotShowingNotification = [],
  showNotification,
}) => {
  try {
    const { status, esmessage, message, statusCode, ...data } = await endpoint();

    if (status !== REQUEST_RESPONSE.SUCCESS) {
      if (showNotification && !errorCodesNotShowingNotification.includes(statusCode)) {
        showNotification(esmessage || message || 'Hubo un error en el servidor', 'error');
      }
      return onFailure(statusCode);
    }
    return onSuccess(data);
  } catch (error) {
    console.error(error);
    return onFailure(error);
  }
};
