import queryString from 'query-string';
import { getToken } from './api.utils';

const PLATFORM = 'web';
const { default: config } = require('../config/config');

const apiRequest = async ({ method, path, body, params, isForm = false }) => {
  const queryParams = queryString.stringify(params);
  const endpoint = `${config.serverURL}/api${path}${queryParams ? `?${queryParams}` : ''}`;

  try {
    const response = await fetch(endpoint, {
      headers: {
        ...(!isForm && { 'Content-Type': 'application/json' }),
        Authorization: getToken(),
      },
      method,
      body: isForm ? body : JSON.stringify(body),
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error('Ocurrió un error al realizar la petición al servidor: ', error);
  }
};

const getRequest = (path, params) => apiRequest({ method: 'GET', path, params });
const postRequest = (path, body) => apiRequest({ method: 'POST', path, body });
const postFormRequest = (path, body) => apiRequest({ method: 'POST', path, body, isForm: true });
const putRequest = (path, body) => apiRequest({ method: 'PUT', path, body });
const deleteRequest = (path, body) => apiRequest({ method: 'DELETE', path, body });
const patchRequest = (path, body) => apiRequest({ method: 'PATCH', path, body });

const api = {
  // purchase
  createOrder: (order) => postRequest('/purchase/manual', order),
  createFreeOrder: (order) => postRequest('/purchase/free', order),
  cancelOrder: (orderId) => postRequest(`/purchase/cancel/${orderId}`),
  refundOrderAmountWithFee: (orderId) => postRequest(`/purchase/refund/total/${orderId}`),
  refundOrderAmountWithoutFee: (orderId) => postRequest(`/purchase/refund/${orderId}`),
  resendOrderMails: (orderId) => postRequest(`/purchase/order/${orderId}/mail`),

  // order
  getOrderById: (orderId) => getRequest(`/order/${orderId}`),
  getOrderByExternalId: (id) => getRequest(`/order/externalId/${id}`),
  getEventOrders: (eventId, options) => getRequest(`/order/event/${eventId}`, options),
  getEventOrdersQrs: (eventId) => getRequest(`/order/event/${eventId}/qrs`),
  getUserEvents: (options) => getRequest(`/order/customer/event`, options),
  getUserEventQrs: (eventId) => getRequest(`/order/customer/${eventId}/qrs`),
  updateOrderMail: (orderId, newMail) => patchRequest(`/order/${orderId}`, { mail: newMail }),

  // paymentMethods
  getEventPaymentMethodsByEventId: (eventId) => getRequest(`/paymentMethods/events/${eventId}`),
  getAvailableCards: (eventId) => getRequest(`/paymentMethods/line/${eventId}/cards`),
  getCardInstallments: (eventId, cardNumber) =>
    getRequest(`/paymentMethods/line/${eventId}/cards/${cardNumber}/installments`),

  // macro
  createMacroPaymentOrder: (orderData) => postRequest('/macro/order', orderData),

  // dlocal
  createDlocalPaymentOrder: (orderData) => postRequest('/dlocal/order', orderData),

  // line
  createLinePaymentOrder: (orderData) => postRequest('/line/order', orderData),
  payLineOrder: (orderId, paymentData) =>
    postRequest(`/line/order/${orderId}/payment`, paymentData),

  // mercadopago
  createMercadoPagoOrder: (orderData) => postRequest('/mercadopago/order', orderData),
  payMercadoPagoOrder: (orderId, paymentData) =>
    postRequest(`/mercadopago/order/${orderId}/payment`, paymentData),
  getMercadoPagoPaymentLink: (orderId) =>
    postRequest(`/mercadopago/order/${orderId}/preference`, {
      hostname: window.location.hostname,
    }),

  // nfts
  getNFTsByExternalId: (externalId) => getRequest(`/nfts/orders/${externalId}`),
  claimNFT: (qrId, lemontagData) => postRequest(`/nfts/${qrId}`, lemontagData),

  // event
  createEvent: (event) => postRequest('/event', event),
  updateEvent: (event) => putRequest('/event', event),
  updateEventSettings: (eventId, settings) => patchRequest(`/event/${eventId}/settings`, settings),
  getEvent: (id) => getRequest(`/event/${id}`),
  getEventByName: (eventName) => getRequest(`/event/name/${eventName}`),
  getEventStadium: (eventId) => getRequest(`/event/${eventId}/stadiumLayout`),
  getEventHiddenTicket: (eventId, ticketTypeExternalId) =>
    getRequest(`/event/${eventId}/ticketType/hidden/${ticketTypeExternalId}`),
  blacklistUsers: (eventId, userIds) =>
    postRequest(`/event/blacklist/${eventId}/users`, { userIds }),
  unblacklistUsers: (eventId, userIds) =>
    deleteRequest(`/event/blacklist/${eventId}/users`, { userIds }),
  createEventStadium: (eventId, stadiumLayoutId, stadiumBody) =>
    postRequest(`/event/${eventId}/stadiumLayout/${stadiumLayoutId}`, stadiumBody),
  updateEventStadium: (eventId, stadiumLayoutId, stadiumBody) =>
    putRequest(`/event/${eventId}/stadiumLayout/${stadiumLayoutId}`, stadiumBody),
  getProducerStadiums: (producerId) => getRequest(`/stadiumLayout/producer/${producerId}`),
  getEventPromotions: (eventId) => getRequest(`/eventPromotions/${eventId}`),
  getPrivateEvents: (options) => getRequest('/private/event', options),

  // events
  getActiveEvents: (options) => getRequest('/event/active', options),

  // ticketType
  createTicketType: (ticketType) => postRequest('/ticketType', ticketType),
  updateTicketType: (ticketType) => putRequest('/ticketType', ticketType),
  getTicketType: (ticketTypeId) => getRequest(`/ticketType/${ticketTypeId}`),
  validateDiscountCode: (ticketTypeId, code) =>
    postRequest(`/ticketType/${ticketTypeId}/discountCode/validation`, code),
  createDiscountCode: (ticketTypeId, discountCode) =>
    postRequest(`/ticketType/${ticketTypeId}/discountCode`, discountCode),
  getDiscountCode: (ticketTypeId) => getRequest(`/ticketType/${ticketTypeId}/discountCode`),
  deleteDiscountCode: (ticketTypeId, code) =>
    deleteRequest(`/ticketType/${ticketTypeId}/discountCode/${code}`),

  // home
  getHomeEvents: (options) => getRequest(`/home/events`, options),
  getFeaturedEvents: () => getRequest('/home/events/featured'),

  // invitations
  getInvitationUsers: (ticketTypeId) => getRequest(`/invitations/${ticketTypeId}`),
  getUserInvitationsByEvent: (eventId) => getRequest(`/invitations/events/${eventId}`),
  sendInvitationBySeller: (ticketTypeId, invitationData) =>
    postRequest(`/invitations/${ticketTypeId}/orders/sellers`, invitationData),
  sendInvitationByAdmin: (ticketTypeId, invitationData) =>
    postRequest(`/invitations/${ticketTypeId}/orders/admins`, invitationData),
  updateInvitationUsers: (ticketTypeId, userInvitations) =>
    postRequest(`/invitations/${ticketTypeId}`, userInvitations),
  sendMultipleInvitationsByAdmin: (ticketTypeId, invitationMailingList) =>
    postRequest(`/invitations/${ticketTypeId}/orders/admins/massive-load`, {
      invitationMailingList,
    }),

  // producer
  createProducer: (producer) => postRequest('/producer', producer),
  updateProducer: (producerId, producer) => patchRequest(`/producer/${producerId}`, producer),
  getProducer: (producerId) => getRequest(`/producer/${producerId}`),
  getAllProducers: (options) => getRequest(`/producer`, options),
  getAllProducersWithEvents: () => getRequest(`/producer/events`),
  getProducerHome: (urlName) => getRequest(`/producer/home/${urlName}`),

  // venues
  searchVenue: (body) => postRequest(`/venue/search`, body),
  createVenue: (body) => postRequest('/venue', body),

  // teamLeaders
  createTeamLeader: (teamLeader) => postRequest('/teamLeader', teamLeader),
  updateTeamLeader: (teamLeaderId, newTeamLeader) =>
    putRequest(`/teamLeader/${teamLeaderId}`, newTeamLeader),
  deleteTeamLeader: (teamLeaderId) => deleteRequest(`/teamLeader/${teamLeaderId}`),
  getProducerTeamLeaders: (producerId) => getRequest(`/teamLeader/producers/${producerId}`),
  addTeamLeaderToUser: (teamLeaderId, userId) =>
    postRequest(`/teamLeader/${teamLeaderId}/user`, userId),
  updateTeamLeaderToUser: (userId, teamLeaderId) =>
    putRequest(`/teamLeader/user/${userId}`, teamLeaderId),
  deleteUserTeamLeader: (teamLeaderId, userId) =>
    deleteRequest(`/teamLeader/${teamLeaderId}/user/${userId}`),
  getTeamLeaderById: (teamLeaderId) => getRequest(`/teamLeader/${teamLeaderId}`),

  // event reports
  getEventReport: (eventId) => getRequest(`/report/event/${eventId}`),

  // producer metrics
  getActiveEventsVolumeMetrics: (producerId) =>
    getRequest(`/metrics/producer/${producerId}/activeEventsVolume`),

  getTotalEventsVolumeMetrics: (producerId) =>
    getRequest(`/metrics/producer/${producerId}/totalEventsVolume`),

  getProducerTopCustomersMetrics: (producerId) =>
    getRequest(`/metrics/producer/${producerId}/topCustomers`),

  getLast60DaysVolume: (producerId) =>
    getRequest(`/metrics/producer/${producerId}/last60DaysVolume`),

  getAmountOrdersByLocationTable: (producerId) =>
    getRequest(`/metrics/producer/${producerId}/ordersByLocation`),

  // authenticate
  checkToken: () => getRequest('/checkToken'),
  login: (mail, password) => postRequest('/authenticate', { mail, password, platform: PLATFORM }),

  // user
  updateCustomer: (user) => patchRequest('/user', user),
  createUser: (user) => postRequest('/user', user),
  updateUser: (user) => putRequest('/user', user),
  getUser: (userId) => getRequest(`/user/${userId}`),
  getUserByMail: (mail) => getRequest(`/user/mail/${mail}`),
  updateUserProducers: (producerUser) => putRequest('/user/producers', producerUser),
  getUserEventsRestrictedAccesses: (userId) => getRequest(`/user/${userId}/eventsRestrictedAccess`),
  getAllProducerUsers: (producerId, options) => getRequest(`/user/producer/${producerId}`, options),
  getAllProducerUsersWithTeamleaders: (producerId, options) =>
    getRequest(`/user/producer/${producerId}/teamLeader`, options),
  getAllProducerUsersWithEventsBlacklisted: (producerId, options) =>
    getRequest(`/user/producer/${producerId}/eventBlacklisted`, options),
  changePassword: (body) => putRequest('/user/password', body),
  validateUserInProducerByAlias: (alias, producerId) =>
    getRequest(`/user/alias/${alias}/producer/${producerId}`),
  deleteProducerUser: (producerId, userId) =>
    deleteRequest(`/producerUser/producer/${producerId}/user/${userId}`),

  // user customer
  signup: (body) => postRequest('/user/customer', body),
  sendCustomerInvitation: (mail) => postRequest('/user/customer/invitation', { mail }),
  verifyTermsAndConditions: () => getRequest('/user/customer/termsAndConds/accept'),
  acceptTermsAndConditions: () => postRequest('/user/customer/termsAndConds/accept'),
  sendCustomerVerificationMail: (body) => postRequest(`/user/customer/confirmation/resend`, body),
  validateMailToken: (token, hostname) =>
    postRequest(`/user/customer/confirmation/${token}`, { hostname }),
  sendResetPasswordToken: (body) => postRequest('/resetPasswordToken/send', body),

  // google services
  validateRecaptcha: (captchaToken) => postRequest('/google-services/captcha', { captchaToken }),
  getGoogleReCaptchaVersion: () => getRequest('/google-services/version'),

  // transfers
  transferQr: (body) => postRequest(`/transfers/qrs/customers`, body),
  returnQrTransfer: (qrExternalId) =>
    postRequest(`/transfers/qrs/customers/return`, { qrExternalId }),

  // secondary market
  deleteQrPostOnSecondaryMarket: (qrExternalId) =>
    postRequest(`/secondaryMarket/qr/${qrExternalId}/publication/cancel`),
  getLinkToPostQrOnSecondaryMarket: (qrExternalId) =>
    getRequest(`/secondaryMarket/qr/${qrExternalId}/sale`),
  getMentaLoginToken: () => getRequest(`/secondaryMarket/token`),

  // images
  uploadImage: (form) => postFormRequest('/upload', form),

  // flags
  getFlag: (flagId) => getRequest(`/flag/${flagId}`),

  // financial operations
  getFinancialOperations: (eventId) => getRequest(`/financialOperation/${eventId}`),
  createFinancialOperation: (body) => postRequest('/financialOperation', body),
  updateFinancialOperationr: (financialOperationId, body) =>
    patchRequest(`/financialOperation/${financialOperationId}`, body),
  deleteFinancialOperation: (financialOperationId) =>
    deleteRequest(`/financialOperation/${financialOperationId}`),

  // settlements
  getSettlement: (eventId, settlementType) =>
    getRequest(`/settlement/${eventId}`, { type: settlementType }),
  sendSettlement: (body) => postRequest('/settlement', body),
};

export default api;
