import React from 'react';
import { Box, Snackbar as MuiSnackbar } from '@mui/material';
import Alert from '../Alert/Alert';

const Snackbar = ({ open, onClose, variant, message }) => {
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      sx={{ width: 344, zIndex: 1200 }}
    >
      <Box width="100%">
        <Alert severity={variant} message={message} />
      </Box>
    </MuiSnackbar>
  );
};

export default Snackbar;
