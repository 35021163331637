import { useState } from 'react';
import { NOTIFICATION_VARIANT } from '../../constants/types';

const initialState = {
  message: '',
  variant: NOTIFICATION_VARIANT.INFO,
  open: false,
};

export const useNotificationsContext = () => {
  const [notificationState, setNotificationState] = useState(initialState);

  const onCloseNotification = () => {
    setNotificationState(initialState);
  };

  const showNotification = (message, variant) => {
    setNotificationState({ message, variant, open: true });
  };

  return { showNotification, notificationState, onCloseNotification };
};
