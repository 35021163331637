import React, { useState } from 'react';
import { Dialog, DialogContent, withStyles, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import HorizontalStepper from '../HorizontalStepper/HorizontalStepper';
import CheckOrderStep from '../Steps/CheckOrderStep/CheckOrderStep';
import styles from './CheckoutHorizontalStepperModal.styles';
import DataFormStep from '../Steps/DataFormStep/DataFormStep';
import ConfirmationStep from '../Steps/ConfirmationStep/ConfirmationStep';

const CheckoutHorizontalStepperModal = ({
  classes,
  openModal,
  onClose,
  initialOrderData,
  cart,
  cartActions,
}) => {
  const [order, setOrder] = useState(initialOrderData);

  return (
    <Dialog
      PaperProps={{ classes: { root: classes.modalContainer } }}
      maxWidth="md"
      fullWidth
      open={openModal}
      onClose={onClose}
      disableEnforceFocus
    >
      <IconButton onClick={onClose} className={classes.closeButton}>
        <Close />
      </IconButton>
      <DialogContent className={classes.modalContent}>
        <HorizontalStepper
          steps={[
            {
              title: 'Revisa tu orden',
              component: (
                <CheckOrderStep
                  cart={cart}
                  cartActions={cartActions}
                  serviceCharge={order.event.serviceCharge}
                  setOrder={setOrder}
                />
              ),
              optional: false,
            },
            {
              title: 'Ingresa tus datos',
              component: <DataFormStep setOrder={setOrder} />,
              optional: false,
            },
            {
              title: 'Confirma tu compra',
              component: <ConfirmationStep cart={cart} order={order} setOrder={setOrder} />,
              optional: false,
            },
          ]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(CheckoutHorizontalStepperModal);
