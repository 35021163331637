import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import styles from './InputTitle.styles';

const InputTitle = ({ classes, title, icon, materialIcon, description, titleStyle }) => {
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        {!!icon && <img alt="icon" src={icon} className={classes.icon} />}
        {materialIcon}
        <Typography
          className={classes.title}
          style={titleStyle}
          align="left"
          component="h1"
          variant="h5"
        >
          {title}
        </Typography>
      </div>
      {description && <Typography className={classes.description}>{description}</Typography>}
    </div>
  );
};

export default withStyles(styles)(InputTitle);
