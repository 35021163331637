import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& +div': {
        marginTop: 16,
      },
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    description: {
      textAlign: 'left',
    },
    title: {
      fontWeight: 500,
      fontSize: 20,
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 10,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      width: 20,
      height: 20,
    },
  });

export default styles;
