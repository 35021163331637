import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    switchContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    controlLabel: {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: 0,
      gap: 10,
    },
    labelAtEnd: {
      justifyContent: 'unset',
      flexDirection: 'row',
      columnGap: 10,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
      },
    },
    switch: {
      '&.MuiSwitch-root': {
        height: 22,
        width: 38,
        padding: '0 1px',
        alignItems: 'center',
        alignSelf: 'start',
      },
      '& .MuiSwitch-switchBase': {
        width: 20,
        height: 20,
        padding: 0,
        marginLeft: 1,

        '&.Mui-checked': {
          transform: 'translateX(17px) translateY(1px)',
          color: theme.palette.primary.main,

          '&.Mui-disabled': {
            color: theme.palette.primary.main,
            opacity: 0.4,
          },
        },
        '& + .MuiSwitch-track': {
          padding: 0,
          height: 14,
          width: 34,
          transform: 'translateX(1px)',
          backgroundColor: theme.palette.grey[400],
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: theme.palette.grey[400],
        },
      },
    },
    errorRoot: {
      backgroundColor: 'transparent',
      border: '2px solid red',
    },
    focused: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.secondary.main,
    },
    icon: {
      alignSelf: 'center',
      width: '1.3em',
      height: '1.3em',
      marginRight: 10,
    },
    iconTitle: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: 0,
      justifyContent: 'space-between',
    },
    helper: {
      color: 'red',
      marginBottom: 10,
      display: 'inline',
      float: 'left',
    },
    length: {
      color: theme.palette.text.color,
      padding: 5,
      display: 'inline',
      float: 'right',
      paddingRight: 10,
    },
  });

export default styles;
