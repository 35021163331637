import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Event.styles';
import Disclaimer from '../../../../Components/BuyComponents/Disclaimer';
import Checkout from '../../../../Components/Checkout/Checkout';
import EventTicketsCard from '../../../../Components/Cards/EventTicketsCard/EventTicketsCard';
import EventHeader from '../../../../Components/EventComponents/EventHeader';
import BluredBackgroundImage from '../../../../Components/EventComponents/BluredBackgroundImage';
import api from '../../../../api/api';
import CartStorage from '../../../../Providers/CartStorage/CartStorage';
import { WHITE_LABELS } from '../../../../constants/whiteLabels';
import { TICKET_VISIBILITY_MODE, REQUEST_RESPONSE } from '../../../../constants/types';
import {
  getThemeByBaseUrl,
  sortArrayOrderByProps,
  userIsBlacklistedFromEvent,
} from '../../../../utils/utils';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import StadiumImage from '../../../../Components/StadiumImage/StadiumImage';
import { handleRequestHelper } from '../../../../utils/helpers';

const queryString = require('query-string');

const parsedUrl = queryString.parse(window.location.search);

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSellerValid: true,
      event: {},
      tickets: [],
      invalidID: false,
      eventWithLemon: false,
      cart: [],
      theme: null,
      defaultOpenCheckout: false,
      loading: true,
      seller: props.urlParams.seller,
    };
  }

  async componentDidMount() {
    const { urlName } = this.props.urlParams;

    if (this.state.seller) this.setState({ seller: this.state.seller.toLowerCase() });

    try {
      const eventData = await api.getEventByName(urlName);
      const tickets = eventData?.event?.TicketTypes;
      const hiddenTicketExternalId = parsedUrl.ticket;
      let cart = [];
      let defaultOpenCheckout = false;

      if (hiddenTicketExternalId) {
        const { status, ticket } = await api.getEventHiddenTicket(
          eventData?.event?.id,
          hiddenTicketExternalId
        );
        if (
          status === REQUEST_RESPONSE.SUCCESS &&
          ticket &&
          ticket.maxQuantity > ticket.soldUnits
        ) {
          cart[ticket.id] = {
            ...ticket,
            soldUnits: ticket.soldUnits,
            maxQuantity: ticket.maxQuantity,
            maxQuantityPerOrder: ticket.maxQuantityPerOrder,
            name: ticket.name,
            priceInCents: ticket.priceInCents,
            price: ticket.priceInCents / 100,
            priceInCentsWithLemon: ticket.priceInCentsWithLemon,
            quantity: ticket.type === 'table' ? ticket.groupSize : 1,
          };
          if (ticket.shouldConvertPriceFromUSD) {
            cart[ticket.id] = {
              ...cart[ticket.id],
              priceInUSD: ticket.priceInCents / 100,
              priceInCents: ticket.priceInCents,
            };
          }

          defaultOpenCheckout = true;
          tickets.push(ticket);
          CartStorage.setStoredEventCart(eventData?.event?.id, cart);
        }
      } else {
        cart = CartStorage.getStoredEventCart(eventData?.event?.id);
      }

      const theme = eventData.event.Producer.theme ?? WHITE_LABELS.VENTI;

      const eventWithLemon = tickets.some((ticket) => {
        return ticket.hasLemonDiscount || ticket.isLemonExclusive;
      });
      if (eventData?.event) {
        if (cart?.length > 0 && !hiddenTicketExternalId) {
          cart.forEach((ticket, index, arr) => {
            const ticketType = eventData.event.TicketTypes.find(
              (ticketType) => ticketType.id === ticket.id
            );
            if (
              !ticketType ||
              !ticketType.canBeSelled ||
              ticketType.visibilityMode === TICKET_VISIBILITY_MODE.HIDDEN
            )
              arr.splice(index, 1);
            else {
              ticket.soldUnits = ticketType.soldUnits;
              ticket.maxQuantity = ticketType.maxQuantity;
              ticket.maxQuantityPerOrder = ticketType.maxQuantityPerOrder;
              ticket.name = ticketType.name;
              ticket.priceInCents = ticketType.priceInCents;
              ticket.price = ticketType.priceInCents / 100;
              ticket.priceInCentsWithLemon = ticketType.priceInCentsWithLemon;
              ticket.quantity = Math.min(
                ticket.quantity,
                ticketType.maxQuantity - ticketType.soldUnits
              );
              ticket.hasDiscountCode = ticketType.hasDiscountCode;
            }
          });
          CartStorage.setStoredEventCart(eventData.event.id, cart);
        }

        this.setState({
          event: eventData.event,
          stadium: eventData.event.eventStadium?.stadium,
          tickets,
          cart,
          theme,
          eventWithLemon,
          defaultOpenCheckout,
        });

        await this.getEventPromotions(eventData.event.id);

        if (this.state.seller) {
          await handleRequestHelper({
            endpoint: () =>
              api.validateUserInProducerByAlias(this.state.seller, eventData.event.producerId),
            onSuccess: ({ aliasIsInProducer }) =>
              this.setState({
                ...this.state,
                isSellerValid:
                  aliasIsInProducer &&
                  !userIsBlacklistedFromEvent(eventData.event, { alias: this.state.seller }),
              }),
            onFailure: () => this.setState({ ...this.state, isSellerValid: false }),
          });
        }

        this.setState({ ...this.state, loading: false });
      }
    } catch (err) {
      this.setState({
        invalidID: true,
        loading: false,
      });
      console.error(err);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.theme !== this.state.theme) this.props.setTheme(this.state.theme);
  }

  componentWillUnmount() {
    const prevTheme = getThemeByBaseUrl();
    this.props.setTheme(prevTheme);
  }

  async getEventPromotions(eventId) {
    await handleRequestHelper({
      endpoint: () => api.getEventPromotions(eventId),
      onSuccess: ({ eventPromotions }) => this.setState({ eventPromotions }),
    });
  }

  addTicketToCartFromCheckout(ticket) {
    const { id, quantity, groupSize } = ticket;
    const newCart = this.state.cart;

    const ticketGroupSize = groupSize || 1;
    const newQuantity = quantity + ticketGroupSize;

    newCart[id] = { ...ticket, quantity: newQuantity };

    this.setState({ cart: newCart });

    CartStorage.setStoredEventCart(this.state.event.id, newCart);
  }

  updateCartTicket(ticket) {
    const { id, quantity } = ticket;

    const newCart = this.state.cart;

    if (newCart[id] && quantity === 0) {
      delete newCart[id];
    }

    if (quantity > 0) {
      newCart[id] = { ...ticket };
    }

    this.setState({ cart: newCart });

    CartStorage.setStoredEventCart(this.state.event.id, newCart);
  }

  removeTicketFromCart(ticket) {
    const { id, groupSize } = ticket;
    const ticketGroupSize = groupSize || 1;
    const newCart = this.state.cart;
    const ticketFromCart = newCart[id];

    if (ticketFromCart) {
      const newQuantity = ticketFromCart.quantity - ticketGroupSize;

      if (ticketFromCart.quantity > ticketGroupSize) {
        newCart[id] = {
          ...ticketFromCart,
          quantity: newQuantity,
        };
      } else {
        delete newCart[id];
      }
    }

    this.setState({
      cart: newCart,
    });

    CartStorage.setStoredEventCart(this.state.event.id, newCart);
  }

  render() {
    const { classes } = this.props;
    let tickets = [...this.state.tickets];

    if (this.state.theme) {
      const fieldToOrderTickets = this.state.event?.eventStadium.stadiumLayoutId
        ? 'ticketTypeSection.0.name'
        : 'name';

      tickets = sortArrayOrderByProps(tickets, fieldToOrderTickets);

      const canNotBeSelledTickets = tickets.filter(
        ({ visibilityMode, canBeSelled }) =>
          visibilityMode === TICKET_VISIBILITY_MODE.VISIBLE && !canBeSelled
      );

      const canBeSelledTickets = tickets.filter(
        ({ visibilityMode, canBeSelled }) =>
          visibilityMode === TICKET_VISIBILITY_MODE.VISIBLE && canBeSelled
      );

      const sortedTickets = canBeSelledTickets.concat(canNotBeSelledTickets);

      const country = this.state.event?.venue?.city?.country;

      return (
        <PageContainer withFooter title={this.state.event.name} country={country}>
          <BluredBackgroundImage bannerImg={this.state.event?.bannerImg} />
          <div className={classes.contentContainer}>
            {this.state.loading ? (
              <CircularProgress size={40} className={classes.activityIndicator} />
            ) : !this.state.isSellerValid ? (
              <Typography className={classes.notFoundSellerText}>
                El link ingresado no es válido o el RRPP no se encuentra activo en este evento.
                <br />
                <br />
                Si cree que esto es un error, verifique nuevamente el enlace con el promotor.
                <br />
                <br />
                <a className={classes.notFoundSellerLink} href="/">
                  Volver al inicio
                </a>
              </Typography>
            ) : (
              <>
                <EventHeader
                  event={this.state.event}
                  seller={this.state.seller}
                  eventPromotions={this.state.eventPromotions}
                />
                <Paper elevation={0} className={classes.titleAndCartContainer}>
                  <Typography className={classes.title} variant="h3">
                    Entradas
                  </Typography>
                  {this.state.stadium && <StadiumImage image={this.state.stadium.image} />}
                  <Checkout
                    defaultOpen={this.state.defaultOpenCheckout}
                    seller={this.state.seller}
                    cart={this.state.cart}
                    addItemToCart={this.addTicketToCartFromCheckout.bind(this)}
                    deleteItemFromCart={this.removeTicketFromCart.bind(this)}
                    tickets={this.state.tickets}
                    loggedUser={this.props.user}
                    event={this.state.event}
                    showNotification={this.props.showNotification}
                  />
                </Paper>
                <div className={classes.eventTicketsList}>
                  {sortedTickets.map((ticket) => (
                    <EventTicketsCard
                      currentValue={this.state.cart[ticket.id]?.quantity}
                      key={ticket.id}
                      addToCart={this.updateCartTicket.bind(this)}
                      ticket={ticket}
                    />
                  ))}
                </div>
                <div className={classes.imageAndDescriptionContainer}>
                  {this.state.event.promoImg && (
                    <img alt="" className="descriptionImage" src={this.state.event.promoImg} />
                  )}
                  {this.state.event.description && (
                    <div className="descriptionContainer">
                      <Typography className="descriptionTitle">Descripción</Typography>
                      <Typography className="description">
                        {this.state.event.description.split('\n').map((i) => i)}
                      </Typography>
                    </div>
                  )}
                </div>
                <Disclaimer country={country} eventWithLemon={this.state.eventWithLemon} />
              </>
            )}
          </div>
        </PageContainer>
      );
    }
    return null;
  }
}

export default withStyles(styles)(Event);
