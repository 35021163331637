import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@mui/material';
import { FormControlLabel } from '@material-ui/core';
import styles from './TitledSwitch.styles';
import InputTitle from '../../Components/InputTitle/InputTitle';

const TitledSwitch = ({
  classes,
  icon,
  title,
  checked,
  onChange,
  name,
  placeholder,
  disabled,
  switchOnTheRight = true,
  titleStyle,
  materialIcon,
  description,
  ...props
}) => (
  <div className={classes.switchContainer} {...props}>
    <FormControlLabel
      labelPlacement="start"
      className={`${classes.controlLabel} ${!switchOnTheRight && classes.labelAtEnd} `}
      control={
        <Switch
          checked={checked || false}
          className={classes.switch}
          onChange={onChange}
          disabled={disabled}
          name={name}
          placeholder={placeholder}
        />
      }
      label={
        <InputTitle
          titleStyle={titleStyle}
          icon={icon}
          materialIcon={materialIcon}
          description={description}
          title={title}
        />
      }
    />
  </div>
);

export default withStyles(styles)(TitledSwitch);
