import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  const limitedText = (lines) => ({
    display: '-webkit-box',
    WebkitLineClamp: lines,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineClamp: lines,
    textOverflow: 'ellipsis',
  });

  const border = `0.5px solid ${theme.palette.background.toolbar}`;

  return createStyles({
    cardContainer: {
      width: 357,
      height: 253,
      borderRadius: 8,
      padding: 0,
      overflow: 'hidden',
      border,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      textDecoration: 'none',
      color: theme.palette.text.primary,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      '& .banner': {
        height: 120,
        width: '100%',
        flex: 1,
        backgroundSize: 'cover',
        borderBottom: border,
        position: 'relative',
        backgroundPosition: 'center',
      },

      '& .cardInfoContainer': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      },

      '& .nameContainer': {
        height: 62,
        textAlign: 'left',
        padding: 8,
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: border,
        display: 'flex',
      },

      '& .name': {
        lineHeight: '19px',
        ...limitedText(2),
        fontWeight: 'bold',
        fontSize: 16,
      },

      '& .dateContainer': {
        height: 24,
        display: 'flex',
        borderBottom: border,
        '& :first-child': {
          borderRight: border,
        },
        '& :last-child': {
          borderLeft: border,
        },
      },

      '& .dateTimeText': {
        fontSize: 12,
        lineHeight: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 7px',
        letterSpacing: '-0.03em',
        flex: 1,
        textTransform: 'capitalize',
      },

      '& .locationContainer': {
        height: 30,
        padding: '0 7px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        gap: 8,
      },

      '& .locationText': {
        fontWeight: 600,
        fontSize: 12,
        ...limitedText(1),
      },

      '@media (hover: hover)': {
        '&:hover': {
          transform: 'translate(-4px, -4px) !important',
          transition: 'transform 300ms, box-shadow 100ms ease',
          boxShadow: `4px 4px 0px ${theme.palette.background.toolbar}`,
          cursor: 'pointer',

          '& .nameContainer': {
            backgroundColor: theme.palette.primary.main,
          },

          '& .name': {
            color: theme.palette.primary.contrastText,
          },

          '& .banner::after': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: theme.palette.primary.main,
            mixBlendMode: 'lighten',
            opacity: 0.6,
          },
        },
      },
    },
  });
};

export default styles;
