import { useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material';
import { handleRequestHelper } from '../../utils/helpers';
import api from '../../api/api';
import { getTextHelperObligatoryField } from '../../Venti-UI-Kit/Input/Errors';

const { v4: uuidv4 } = require('uuid');

export const useVenueAutocomplete = (checkInputError, venue, placeName) => {
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [venueOptions, setVenueOptions] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingVenue, setIsLoadingVenue] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [userLocation, setUserLocation] = useState({});
  const [session] = useState(uuidv4());
  const [errorMessage, setErrorMessage] = useState('');

  const isLoading = isLoadingOptions || isLoadingVenue;

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const getVenues = async (searchQuery) => {
    setSearchQuery(searchQuery);

    if (!searchQuery) {
      setVenueOptions([]);
      return;
    }
    setIsLoadingOptions(true);

    await handleRequestHelper({
      endpoint: () => api.searchVenue({ searchQuery, session, ...userLocation }),
      onSuccess: ({ places }) => {
        const options = places.map(({ description, placeId }) => ({
          label: description,
          placeId,
          ...userLocation,
        }));
        options.push({ label: `Usar "${searchQuery}"`, isPlaceName: true });
        setVenueOptions(options);
      },
    });

    setIsLoadingOptions(false);
  };

  const onChangeSearch = useMemo(
    () => debounce(({ target: { value } }) => getVenues(value), 500),
    [getVenues]
  );

  const handleOpenAutocomplete = () => setOpenAutocomplete(true);
  const handleCloseAutocomplete = () => setOpenAutocomplete(false);

  useEffect(() => {
    const error = checkInputError(
      placeName || venue?.placeName,
      'placeName',
      getTextHelperObligatoryField
    );

    setErrorMessage(error);
  }, [venue, placeName]);

  return {
    openAutocomplete,
    onChangeSearch,
    venueOptions,
    searchQuery,
    isLoading,
    handleOpenAutocomplete,
    handleCloseAutocomplete,
    setIsLoadingVenue,
    errorMessage,
  };
};
